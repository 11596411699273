/* Youtube Tutorial https://github.com/fireship-io/react-firebase-chat/blob/master/src/App.js */
import React, { useRef, useState } from 'react';

import './App.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

/* ME */
import logo from './TV-01.png';



firebase.initializeApp({
  apiKey: "AIzaSyAKtdGDNeRHfiGDN6O3p_SB_RdDHofe7R0",
  authDomain: "spectrovr-01.firebaseapp.com",
  projectId: "spectrovr-01",
  storageBucket: "spectrovr-01.appspot.com",
  messagingSenderId: "848816785541",
  appId: "1:848816785541:web:22cb444151f4988029c1ec",
  measurementId: "G-CJ5YB2GB2M"
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();



function App() {
const [user] = useAuthState(auth);

  return (
    <div className="App">
        <header className="App-header">
            <h1>
              SVR CHAT TEST
            </h1>
            {user ? <SignOut /> : <SignIn />}
        </header>
          
        <div>
            <body className="App-body">
                <div className="TwoBlockGrid">
                    <div className='TV-block'>
                        <img src={logo}/>
                    </div>
                    <div className='Chat-block'>
                        {user ? <ChatRoomSignedIn /> : <ChatRoomSignedOut />}
                    </div>
                </div>
            </body>
        </div> 
    </div>
  );
}
    
 
function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <button className="SignInOutButton" onClick={signInWithGoogle}>Sign in with Google</button>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="SignInOutButton" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoomSignedIn() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limitToLast(30);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL, displayName } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    <form onSubmit={sendMessage}>

      <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="say something nice" />

      <button type="submit" className="comment-Button" disabled={!formValue}>COMMENT</button>

    </form>
  </>)
}

function ChatRoomSignedOut() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limitToLast(30);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL, displayName } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    <form>

      <input placeholder="sign in to comment" />


    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL, displayName } = props.message;

  return (<>
    <div className = "comment">
      <p className="user-Name"> {displayName} </p>
      <p className='comment-Text'>{text}</p>
    </div>
  </>)
   
}

export default App;
